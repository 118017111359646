import React from "react"
import { Alert, Button } from "react-bootstrap"
import Date from "../components/date"

const NoticeBoard = () => (
  <>
    <h1>Notice</h1>
    <div>
      <Alert variant="success">
        <Alert.Heading>Regarding application for M.Ed. Session - 2023-25 </Alert.Heading>
       
        <p className="mb-0">
          <a href="../documents/UDE3124.jpg">
            <Button variant="outline-dark" size="lg" block>
             Notice  
            </Button>
          </a>
        </p>
         <br />
        
      </Alert>
    </div>
     <div>
      <Alert variant="success">
        <Alert.Heading>Notice regarding PAT 21 (Education)</Alert.Heading>
       
        <p className="mb-0">
          <a href="../documents/notice-reg-pat-21.pdf">
            <Button variant="outline-dark" size="lg" block>
             Notice  
            </Button>
          </a>
        </p>
         <br />
        
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>Date of online application extended for M.Ed. Entrance Test [MET] 2022 </Alert.Heading>
       
        <p className="mb-0">
          <a href="../documents/extendAdvtMET2022.pdf">
            <Button variant="outline-dark" size="lg" block>
             Advertisment  
            </Button>
          </a>
        </p>
         <br />
        
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>Advertisment for M.Ed. Entrance Test [MET] 2022 </Alert.Heading>
       
        <p className="mb-0">
          <a href="../documents/AdvtMET2022.pdf">
            <Button variant="outline-dark" size="lg" block>
             Advertisment  
            </Button>
          </a>
        </p>
         <br />
        <p className="mb-0">
          <a href="../documents/InformationBrochureMET2022.pdf">
            <Button variant="outline-dark" size="lg" block>
             Information Brochure  
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>MET 2021 On Spot Merit List</Alert.Heading>
       
        <p className="mb-0">
          <a href="../documents/OnSpotMeritListMET2021.pdf">
            <Button variant="outline-dark" size="lg" block>
              View On Spot Merit List 
            </Button>
          </a>
        </p>
         
     
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>MET 2021 Third Merit List</Alert.Heading>
       
        <p className="mb-0">
          <a href="../documents/ThirdMeritListMET2021.pdf">
            <Button variant="outline-dark" size="lg" block>
              View Third Merit List 
            </Button>
          </a>
        </p>
         
     
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>MET 2021 Second Merit List</Alert.Heading>
       
        <p className="mb-0">
          <a href="../documents/SecondMeritListMET2021.pdf">
            <Button variant="outline-dark" size="lg" block>
              View Second Merit List 
            </Button>
          </a>
        </p>
         
     
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>M.Ed. Admission Notice 2021-23 </Alert.Heading>
       
        <p className="mb-0">
          <a href="../documents/MET-21_AdmissionInstruction.pdf">
            <Button variant="outline-dark" size="lg" block>
             Instruction for M.Ed. Admission 2021-23 
            </Button>
          </a>
        </p>
        <p className="mb-0">
          <a href="../documents/MET-21_AdmissionAffidavitForm.pdf">
            <Button variant="outline-dark" size="lg" block>
             Affidavit Form for M.Ed. Admission 2021-23 
            </Button>
          </a>
        </p>
        <p className="mb-0">
          <a href="../documents/MET-21_ApplicationForm.pdf">
            <Button variant="outline-dark" size="lg" block>
             Application form for M.Ed. Admission 2021-23 
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>MET 2021 First Merit List</Alert.Heading>
       
        <p className="mb-0">
          <a href="../documents/MET-21_MeritList.pdf">
            <Button variant="outline-dark" size="lg" block>
              View First Merit List 
            </Button>
          </a>
        </p>
         
     
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>[MET 2021] Final notice to submit a copy of marksheet to the department</Alert.Heading>
        <p> 
         Final notice to submit a copy of marksheet for applicants those who have not submitted theirs related to M.Ed. Entrance Test 2021 held in the month of Feburary, 2022. See attached notice for more details. 
        </p>
        <hr />
        <p className="mb-0">
          <a href="../documents/NoticeDocumentsSubmissionMET21.jpg">
            <Button variant="outline-dark" size="lg" block>
              View Notice
            </Button>
          </a>
        </p>
         
     
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>MET 2021 Result Notification</Alert.Heading>
        <p> 
         M.Ed. Entrance Test 2021 held in the month of Feburary, 2022. 
        </p>
        <hr />
        <p className="mb-0">
          <a href="../documents/MET21_Marksheet.pdf">
            <Button variant="outline-dark" size="lg" block>
              Download Marks List [PDF]
            </Button>
          </a>
        </p>
         
     
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>Examination Notice MET 2021</Alert.Heading>
        <p> 
          Date: 25th Feburary, 2022 <br />
          Time: 12:30 PM - 2:30 PM <br />
          Venue: Exam Centre Code - 1123, Science Block, P.G. North Campus, B.N.M.U., Madhepura, Bihar. <br />
        </p>
        <hr />
        <p className="mb-0">
          <a href="../documents/NoticeMET2021.png">
            <Button variant="outline-dark" size="lg" block>
              Notification
            </Button>
          </a>

          &emsp;  &emsp;
          <a href="https://applications.doedbnmu.in">
            <Button variant="outline-dark" size="lg" block>
              Online Applicants can download their admit card here.
            </Button>
          </a>
          <br />  <br />
           Offline Applicants can get their admit card from the office.
        </p> <br />
     
      </Alert>
    </div>
   
    <div>
      <Alert variant="info">
        <Alert.Heading>Walk-in Interview for Teaching Faculty</Alert.Heading>
        <p>5.1.2021 </p>
        <hr />
        <p className="mb-0">
          <a href="../documents/WalkinInterview05012022.pdf">
            <Button variant="outline-dark" size="lg" block>
              Notification
            </Button>
          </a>
        </p> <br />
        <p className="mb-0">
          <a href="../documents/ApplicationFormTeachingFaculty.pdf">
            <Button variant="outline-dark" size="lg" block>
              Download Application Form
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    <div>
      <Alert variant="info">
        <Alert.Heading>M.Ed. Entrance Test [MET] 2021</Alert.Heading>
        <p>Updates: 5.1.2022  — M.Ed.</p>
        <hr />
        <p className="fs-5">
        
        Update: Application submission deadline has been extended to 10th January, 2022. 
        <br />
        <p className="mb-0">
          <a href="../documents/DeadlineExtMET2021.pdf">
            <Button variant="outline-dark" size="lg" block>
             Deadline extension notification for MET 2021 
            </Button>
          </a>
        </p>
        </p>
        <hr />
        <p className="mb-0">
          <a href="../documents/MET2021-Notification.pdf">
            <Button variant="outline-dark" size="lg" block>
              Notification for MET 2021
            </Button>
          </a>
          <br />
          <br />
          <a href="../documents/MET2021-ApplicationForm.pdf">
            <Button variant="outline-dark" size="lg" block>
              Offline Application Form for MET 2021
            </Button>
          </a>
          <br />
          <br />
          <a href="https://applications.doedbnmu.in">
            <Button variant="outline-dark" size="lg" block>
              Online Application Form for MET 2021
            </Button>
          </a>
         
        </p>
        <br />
        <p className="mb-0">
          <a href="../documents/MEd/InformationBrochureMET-2020.pdf">
            <Button variant="outline-dark" size="lg" block>
              Information Brochure MET
            </Button>
          </a>
        </p><br />
        
      </Alert>
    </div>


    <div>
      <Alert variant="info">
        <Alert.Heading>B.Ed. Spot Admission</Alert.Heading>
        <p>27.10.2021 — B.Ed.</p>
        <hr />
        <p className="mb-0">
          <a href="../documents/B.Ed.Spot.Admission21.pdf">
            <Button variant="outline-dark" size="lg" block>
              Download Form for B.Ed. Spot Admission
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading>One Day Workshop on INTELLECTUAL PROPERTY RIGHTS (IPR)</Alert.Heading>
        <p>20.09.2021 — Workshop.</p>
        <hr />
        <p className="mb-0">
          <a href="../documents/IPR-Workshop-22nd-Sept-2021.pdf">
            <Button variant="outline-dark" size="lg" block>
            Brochure
            </Button>
          </a>
        </p>

        <br />
         <p className="mb-0">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScCJ7Eo56e27hdonlcCTmRa-LIRWEw9-34j-tWZqDmSPNfLGg/viewform" target="_blank">
            <Button variant="outline-dark" size="lg" block>
            Registration Form 
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    <div>
      <Alert variant="info">
        <Alert.Heading>Second Merit List for MET 2020</Alert.Heading>
        <p>14.06.2021 — M.Ed.</p>
        <hr />
        <p className="mb-0">
          <a href="../documents/MET-2020-Merit-List-2.pdf">
            <Button variant="outline-dark" size="lg" block>
            Second Merit List for MET 2020
            </Button>
          </a>
        </p>
        <br />
        <p className="mb-0">
          <a href="https://doedbnmu.in/#:~:text=m.ed.%20admission%20notice%20(2020-22)" target="_blank">
            <Button variant="outline-dark" size="lg" block>
            Admission Notice (Last date for admission is 18th June, 2021)
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading> M.Ed. (Iˢᵗ Semester) Admission extended till 10ᵗʰ June, 2021</Alert.Heading>
        <p>04.06.2021 — M.Ed.</p>
        <hr />
        <p className="mb-0">
          Last date for admission in M.Ed. Iˢᵗ Semester (2020-2022) has been extended till 10ᵗʰ June, 2021.
        </p>
      </Alert>
    </div>
    <div>
      <Alert variant="primary">
        <Alert.Heading> M.Ed. Admission Notice (2020-22)</Alert.Heading>
        <p>31.05.2021 — M.Ed.</p>
        <hr />
        <p className="mb-0">
          <a href="../documents/M.Ed. 2020-22 Admission Instructions.pdf">
            <Button variant="outline-dark" size="lg" block>
            Instructions for M.Ed. Admission 2020-2022
            </Button>
          </a>
        </p>
        <br />
        <p className="mb-0">
          <a href="../documents/M.Ed. 2020-22 Application Form.pdf">
            <Button variant="outline-dark" size="lg" block>
            M.Ed. 2020-22 Application Form
            </Button>
          </a>
        </p>
        <br />
        <p className="mb-0">
          <a href="../documents/M.Ed. 2020-22 Affidavit Format.pdf">
            <Button variant="outline-dark" size="lg" block>
            M.Ed. 2020-22 Affidavit Format
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    <div>
      <Alert variant="success">
        <Alert.Heading> MET 2020 Merit List with Disposal of objections raised by candidates</Alert.Heading>
        <p>15.05.2021 — M.Ed.</p>
        <hr />
        <p className="mb-0">
          <a href="../documents/MET-2020-Merit-List-1.pdf">
            <Button variant="outline-dark" size="lg" block>
            M.Ed. Entrance Exam (MET) - 2020 Merit-cum-Reservation Roster List (CTE-Saharsa 50 + BNMU Campus 50)
            </Button>
          </a> 
          <br />
          <a href="../documents/objection-disposal.pdf">
            <Button variant="outline-dark" size="lg" block>
            Disposal of objections raised by candidates vide UDE/113/21 dated 10/04/2021
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    <hr></hr>
    
    <div>
      <Alert variant="primary">
        <Alert.Heading> Important Notice for canditates appearing for MET 2020</Alert.Heading>
        <p>06.03.2021 — M.Ed.</p>
        <hr />
        <p className="mb-0">
          <a href="../documents/Notice-Appearing-MarkSheet-MET-2020.pdf">
            <Button variant="outline-dark" size="lg" block>
            Last date for marks-sheet submission of B.Ed./D.El.Ed for candidates appearing for MET 2020
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    
    <br></br>
    <div>
      <Alert variant="success">
        <Alert.Heading>M.Ed. Entrance Test 2020</Alert.Heading>
        <p>06.12.2020 — M.Ed.</p>
        <hr />
        <p className="mb-0">
          <a href="../admit-card/">
            <Button variant="outline-dark" size="lg" block>
              Download Admit Card for MET 2020
            </Button>
          </a>
        </p> <br />
        <p className="mb-0">
          <a href="../documents/MEd/met-exam-notice.pdf">
            <Button variant="outline-dark" size="lg" block>
              Examination Notice MET 2020
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    
    <br></br>
    <div>
      <Alert variant="primary">
        <Alert.Heading>M.Ed. Admission Notice & Information Brochure (2020)</Alert.Heading>
        <p>19.10.2020 — M.Ed.</p>
        <hr />
        <p className="mb-0">
          <a href="../documents/MEd/InformationBrochureMET-2020.pdf">
            <Button variant="outline-dark" size="lg" block>
              Information Brochure MET-2020
            </Button>
          </a>
        </p><br />
        <p className="mb-0">
          <a href="../documents/MEd/AdmissionNoticeMET-2020.pdf">
            <Button variant="outline-dark" size="lg" block>
              Admission Notice MET-2020
            </Button>
          </a>
        </p><br />
        <p className="mb-0">
          <a href="../documents/MEd/ApplicationFormMET-2020.pdf">
            <Button variant="outline-dark" size="lg" block>
              Application Form MET-2020
            </Button>
          </a>
        </p>
      </Alert>
    </div>
   
    <br></br>
    <div>
      <Alert variant="success">
        <Alert.Heading>List of admitted students in B.Ed.</Alert.Heading>
        <p>20.03.2020 — B.Ed.</p>
        <hr />
        <p className="mb-0">
          <a href="../documents/Admitted.Students.in.B.Ed.pdf">
            <Button variant="outline-dark" size="lg" block>
              Download list
            </Button>
          </a>
        </p>
      </Alert>
    </div>
    <hr></hr>

    <Date />
  </>
)
export default NoticeBoard
